import React from "react"
import Layout from "../components/layout"
import ImageContainer from "../components/imageContainer"
import bLogo from "../images/bhellobuild2.png"
import InImg from "../images/in2.png"
import BgAbout from "../components/bgAbout"
import FormScreenComp from "../components/formScreenComp"

const Style= {
  ContactUsMain: "container relative px-4 pt-32 sm:pt-24 sm:px-6 md:px-6 lg:px-24 xl:px-20 lg:pt-40",
  ContactUsTitle: "frk-bold text-primary text-2xl sm:text-3xl lg:text-5xl",
  ContactUsText: "frk-regular text-primary text-lg my-2 sm:my-2 lg:text-3xl xl:my-2",
  SelectorDiv: "xl:flex xl:justify-between",
  HelloBuildCardMain: "bg-tertiary  py-8 my-12 mt-10 sm:py-6 sm:flex xl:my-5 xl:h-84 xl:flex-col xl:w-3/12",
  BlogoDiv: "w-12  mx-auto my-4 sm:ml-10 xl:m-auto",
  HelloBuildText: {
    small: "text-center w-full  sm:w-10/12 sm:text-left sm:flex  sm:justify-between",
    large: "xl:w-full xl:text-center xl:flex-col xl:m-auto"
  },
  HelloBuildCardTitle: "frk-bold text-white text-2xl mb-2",
  HelloBuildCardText: "frk-regular text-white text-lg",
  SocialIconSection: "mx-auto w-12  mt-6 sm:mx-16 xl:mx-auto",
  SocialIcon: "sm:w-full"
}

const HelloBuildCard = () => {
  return (
    <section className={Style.HelloBuildCardMain}>
      <ImageContainer
        container={Style.BlogoDiv}
        src={bLogo}
        alt={bLogo}
      />
      <div className={`${Style.HelloBuildText.small} ${Style.HelloBuildText.large}`}>
        <div>
          <h3 className={Style.HelloBuildCardTitle}>
            HelloBuild
          </h3>
          <p className={Style.HelloBuildCardText}>
            2222 Ponce de Leon Blvd <br /> Coral Gables, FL 33134 USA
          </p>
        </div>
        <div className={Style.SocialIconSection}>
          <a
            href="https://www.linkedin.com/company/hellobuild"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImageContainer container={Style.SocialIcon} src={InImg} alt={bLogo} />
          </a>
        </div>
      </div>
    </section>
  )
}

class ContactUs extends React.Component {
  render() {
    return (
      <Layout title="Contact Us" link="/contact-us">
        <BgAbout bLogoShow="block" />
        <main className={Style.ContactUsMain}>
          <h2 className={Style.ContactUsTitle}>
            Let’s work together
          </h2>
          <h3 className={Style.ContactUsText}>
            We are ready to build something great with your company.
          </h3>
          <div className={Style.SelectorDiv}>
            <FormScreenComp />
            <HelloBuildCard />
          </div>
        </main>
      </Layout>
    )
  }
}

export default ContactUs
