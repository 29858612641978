import React from "react"
import Send from "../images/send.png"
import ImageContainer from "../components/imageContainer"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ReCAPTCHA from "react-google-recaptcha"
import ButtonHb from "../components/buttonHB"

const Style = {
  MainSection: "bg-white  xl:w-9/12 xl:mb-84 xl:mr-4",
  FormMainDiv: "flex flex-wrap justify-between w-full my-4 py-4 px-3 sm:py-6 xl:pr-12",
  InputDiv: "w-full mb-4 sm:w-48p",
  InputLabel: "w-full  px-1 frk-regular text-primary text-sm",
  InputClass: "w-full appearance-none  border-2 rounded  py-2 px-3 frk-regular text-primary text-sm",
  InputErrorMsgText: "text-xs px-1 text-red-600",
  TextAreaDiv: "w-full mb-4",
  TextAreaLabel: "text-sm frk-regular px-1 text-primary w-full",
  TextAreaText: "w-full h-64 appearance-none border rounded py-2 px-3  frk-regular text-primary text-sm",
  SendInfoDiv:"w-full h-auto",
  ReCAPTCHADiv: "mb-12 sm:mb-8",
  ErrorText: "text-xs text-red-600",
  SendInfoButtonDiv: "w-full sm:w-48",
  SendInfoButton: "w-full text-base bg-primary text-white mt-4 flex justify-between pl-4 pr-24 py-4 sm:py-4 sm:pr-8",
  CompleteScreenSection: "flex justify-center items-center bg-white mt-0 sm:mt-12 xl:mt-16 h-96",
  SendIconDiv: "w-32 sm:w-40  mx-auto mb-6",
  SuccessMsg: "text-center frk-bold text-primary text-xl sm:text-3xl",
  SpanClass: "text-red-600"
}

const SpanCom = ({ require }) => {
  if (require && require.length > 0)
    return <span className={Style.SpanClass}>*</span>
  else return ""
}

const InputComponent = ({
  labelName,
  requireStatus,
  value,
  onFocus,
  inputName,
  placeholder,
  onChange,
  errorStatus,
  errorMessage,
}) => {
  return (
    <div className={Style.InputDiv}>
      <label
        className={Style.InputLabel}
        htmlFor={`${inputName}_id`}
      >
        {labelName} <SpanCom require={errorStatus} />
      </label>
      <input
        type="text"
        className={`${Style.InputClass} ${requireStatus}`}
        id={`${inputName}_id`}
        value={value}
        onFocus={onFocus}
        placeholder={placeholder}
        name={inputName}
        onChange={onChange}
      />
      <p className={`${Style.InputErrorMsgText} ${errorStatus}`}>
        {errorMessage}
      </p>
    </div>
  )
}

class FormScreenComp extends React.Component {
  state = {
    success: false,
    FNAME: "",
    EMAIL: "",
    REPEMAIL: "",
    COMPANY: "",
    MMERGE4: "",
    token: "",
    requireFNAME: "",
    errorFNAME: "hidden",
    requireEMAIL: "",
    errorEMAIL: "hidden",
    errorMessageEMAIL: "",
    requireREPEMAIL: "",
    errorREPEMAIL: "hidden",
    requireToken: "border-none",
    errorToken: "hidden",
  }

  OnFocusInput = e => {
    this.setState({
      [`require${e.target.name}`]: "",
      [`error${e.target.name}`]: "hidden",
    })
  }

  verifyReCaptcha = value => {
    let verify = value && value.length > 0 ? value : ""
    if (value && value.length > 0 && this.state.errorToken === "block") {
      this.setState({
        token: verify,
        errorToken: "hidden",
        requireToken: "border-none",
      })
    } else {
      this.setState({ token: verify })
    }
  }

  handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    let KeyValidation = true
    if (this.state.EMAIL !== this.state.REPEMAIL) {
      let FnameReq = this.state.FNAME && this.state.FNAME.length > 0
      KeyValidation = false
      this.setState({
        requireEMAIL: "border-tertiary",
        requireFNAME: FnameReq ? "border-tertiary" : "",
        requireREPEMAIL: "border-red-600",
        errorREPEMAIL: "block",
      })
    }
    if (this.state.EMAIL === "") {
      KeyValidation = false
      this.setState({
        requireEMAIL: "border-red-600",
        errorEMAIL: "block",
      })
    }
    if (this.state.FNAME === "") {
      KeyValidation = false
      this.setState({
        requireFNAME: "border-red-600",
        errorFNAME: "block",
      })
    }
    if (this.state.token === "") {
      KeyValidation = false
      this.setState({
        requireToken: "border-red-600",
        errorToken: "block",
      })
    }
    if (KeyValidation === true && this.state.errorEMAIL === "hidden") {
      const result = await addToMailchimp(this.state.EMAIL, this.state)
      if (result.result === "error") {
        this.setState({
          requireEMAIL: "border-red-600",
          errorEMAIL: "block",
          errorMessageEMAIL: "Invalid email",
          requireFNAME: "border-tertiary",
          errorFNAME: "hidden",
          requireREPEMAIL: "border-red-600",
          errorREPEMAIL: "hidden",
        })
      } else if (result.result === "success") {
        this.setState({
          success: true,
          requireEMAIL: "border-tertiary",
          errorEMAIL: "hidden",
          errorMessageEMAIL: "",
          requireFNAME: "border-tertiary",
          errorFNAME: "hidden",
          requireREPEMAIL: "border-tertiary",
          errorREPEMAIL: "hidden",
        })
      }
    }
  }

  Form = () => (
    <form>
      <div className={Style.FormMainDiv}>
        <InputComponent
          labelName="Name"
          requireStatus={this.state.requireFNAME}
          value={this.state.FNAME}
          onFocus={e => this.OnFocusInput(e)}
          placeholder="Your Name Here"
          inputName="FNAME"
          onChange={e => this.handleChange(e)}
          errorStatus={this.state.errorFNAME}
          errorMessage="Please enter your name"
        />
        <InputComponent
          labelName="Company"
          value={this.state.COMPANY}
          placeholder="Your Company Here"
          inputName="COMPANY"
          onChange={e => this.handleChange(e)}
        />
        <InputComponent
          labelName="Email"
          requireStatus={this.state.requireEMAIL}
          value={this.state.EMAIL}
          onFocus={e => this.OnFocusInput(e)}
          placeholder="Your Email Here"
          inputName="EMAIL"
          onChange={e => this.handleChange(e)}
          errorStatus={this.state.errorEMAIL}
          errorMessage={
            this.state.errorMessageEMAIL &&
            this.state.errorMessageEMAIL.length > 0
              ? this.state.errorMessageEMAIL
              : "Please enter your email"
          }
        />
        <InputComponent
          labelName="Repeat Email"
          requireStatus={this.state.requireREPEMAIL}
          value={this.state.REPEMAIL}
          onFocus={e => this.OnFocusInput(e)}
          placeholder="Repeat Your Email Here"
          inputName="REPEMAIL"
          onChange={e => this.handleChange(e)}
          errorStatus={this.state.errorREPEMAIL}
          errorMessage="Emails don't match"
        />

        <div className={Style.TextAreaDiv}>
          <label
            className={Style.TextAreaLabel}
            htmlFor="MMERGE4_id"
          >
            Message
          </label>
          <textarea
            type="text"
            id="MMERGE4_id"
            className={Style.TextAreaText}
            name="MMERGE4"
            onChange={e => this.handleChange(e)}
          />
        </div>
        <div className={Style.SendInfoDiv}>
          <div className={Style.ReCAPTCHADiv}>
            <ReCAPTCHA
              size="normal"
              className={`border-2  ${this.state.requireToken}`}
              style={{ width: "306px" }}
              hl="en"
              sitekey={process.env.GATSBY_RECAPCHA_SITE_KEY ? process.env.GATSBY_RECAPCHA_SITE_KEY : "xxxx"}
              onChange={value => this.verifyReCaptcha(value)}
            />
            <p
              className={`${Style.ErrorText} ${this.state.errorToken}`}
            >
              Please verify that you are a human.
            </p>
          </div>
          <div className={Style.SendInfoButtonDiv}>
            <ButtonHb
              type="button"
              text="Send Message"
              image="pinImage.png"
              className={Style.SendInfoButton}
              onClickTypeButton={e => this.handleSubmit(e)}
            />
          </div>
        </div>
      </div>
    </form>
  )

  CompleteScreenComp = () => {
    return (
      <section className={Style.CompleteScreenSection}>
        <div className="">
          <ImageContainer
            container= {Style.SendIconDiv}
            src={Send}
            alt="send"
          />
          <p className={Style.SuccessMsg}>
            Thanks for getting in touch!
          </p>
        </div>
      </section>
    )
  }

  render() {
    return (
      <section className={Style.MainSection}>
        {this.state.success ? this.CompleteScreenComp() : this.Form()}
      </section>
    )
  }
}

export default FormScreenComp
